a {
  color: #cacaca;
}

.main-container {
  text-align: center;
  background-color: #171717;
  color: #cacaca;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  margin: 0;
}

.text {
  font-size: calc(4px + 2vmin);
}

.footer {
  font-size: calc(4px + 1vmin);
}

.link {
  color: #41b2fe;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
